import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, Form, Image, InputGroup, Row, Tab, Tabs } from "react-bootstrap";


function BGRM_demo_play(props){

    const [img_preview, setImgPreview] = useState(process.env.PUBLIC_URL + '/assets/img/select_img.png')
    const [img_upload, setImgUpload] = useState("");
    const [img_get, setImgGet] = useState("");
    const [img_result, setImgResult] = useState(process.env.PUBLIC_URL + '/assets/img/ready_to_bgrm.png')
    const [transparent, setTransparent] = useState(false);

    const api_code = "bgrm_demo";
    const [api_subname, setAPISubname] = useState("singleImage");
    const api_list = ["2025-02-02", "2023-04-25", "2023-08-23"];
    const [api_version, setAPIVersion] = useState(api_list[0]);

    const timeout = 90000;

    const onLoadFile = (e)=>{
        setImgUpload(e.target.files);
    }

    const onChangeAPIVersion = (e)=>{
        setAPIVersion(e.target.value);
    };

    const handleTransparent = () =>{
        setTransparent(!(transparent));
    };

    const Request = ()=>{
        if(!img_upload){
            alert("이미지를 선택해주세요!");
            return;
        }
        if(!api_version){
            alert("API버전을 선택해주세요!");
            return;
        }
        if(img_upload[0].type !== 'image/jpeg' && img_upload[0].type !== 'image/jpg' && img_upload[0].type !== 'image/png'){
            alert("JPG, PNG 파일만 업로드 가능합니다.");
            return;
        }
        if(img_preview){
            props.setLoading(true);
            axios.post('/rest/webapiDemo', {
                service_uid : 2,
                api_code : api_code,
                api_subname : api_subname,
                'api-version' : api_version,
                transparent : transparent == true ? "1" : "0",
                image : img_preview.substring(img_preview.indexOf("base64,")+7),
            }).then((response)=>{
                if(response.data.result == 'success'){
                    setImgGet(response.data.data);
                }else if(response.data.data.msg === 'Credit Exceed'){
                    alert("1회 사용 가능 횟수를 초과하였습니다. 크레딧을 구매하시거나, 24시간 후 다시 시도해 주세요.");
                    window.open('/subscribe');
                }else{
                    alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                }
                props.setLoading(false);
            }).catch((error)=>{
                alert("요청 실패! 잠시후 다시시도해 주시거나 고객센터로 문의해주세요");
                props.setLoading(false);
            });
        }

    }

    const resetConfig = ()=>{
        document.getElementById("img_file").value="";
        document.getElementById("zip_file").value="";
        setImgPreview(process.env.PUBLIC_URL + '/assets/img/select_img.png');
        setImgUpload("");
        setImgGet("");
        setImgResult(process.env.PUBLIC_URL + '/assets/img/ready_to_bgrm.png');
        setTransparent(false);
        setAPIVersion(api_list[0]);
    }

    const downloadFile = (data) => {      
        const byteString = atob(data.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        let type = data.substring(data.indexOf(":")+1, data.indexOf(";"));
        if(type=="image/jpg")
            type="image/jpeg";
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
            type: type,
        });

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download="";
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
            window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
    };

    useEffect(()=>{
        if(!img_upload || img_upload.length == 0){ 
            resetConfig();    
            return;
        }
        const reader = new FileReader();
        reader.onload = ()=>(setImgPreview(reader.result));
        reader.readAsDataURL(img_upload[0]);
    }, [img_upload]);
    useEffect(()=>{
        if(!img_get) return;
        //let imageurl = URL.createObjectURL(img_get);
        if(transparent)
            setImgResult(`data:image/png;base64,${img_get.data}`);
        else
            setImgResult(`data:image/jpg;base64,${img_get.data}`);
    }, [img_get]);
    useEffect(()=>{

    }, []);

    return(
        <>
            <Breadcrumb className="section-content">
                <Breadcrumb.Item href="/index">홈</Breadcrumb.Item>
                <Breadcrumb.Item active>이미지배경제거 AI 웹 API 데모</Breadcrumb.Item>
            </Breadcrumb>
            <section className="sections-white">
                <Container className="section-content">
                    <Tabs>
                        <Tab eventKey="singleImage" title="단일 이미지"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ 본 데모는 API의 성능을 확인하기 위한 용도로, 데모에 한정하여 <br/>워터마크가 같이 출력됩니다.</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>워터마크가 없는 결과물이 필요하시면 크레딧을 구매하시기 바랍니다.</span>
                                                    </Row>
                                                    <Row>
                                                        <span>하루 최대 5회까지 가능합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="mt-3">
                                            <Form.Label>API버전 선택</Form.Label>
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    {
                                                        api_list.map(function(a, idx){
                                                            return(
                                                            <option key={idx} value={a}>{a}</option>
                                                            );
                                                        })
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Group className="my-3">
                                                <Form.Label>이미지 선택</Form.Label>
                                                <Form.Control type="file" accept="image/jpg, image/jpeg, image/png" id="img_file" onChange={onLoadFile}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group>
                                                <Form.Check name="transparent" type="switch" checked={transparent} onChange={handleTransparent} label="배경 투명하게"></Form.Check>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong className="fs-6">※ 배경 투명하게가 선택되면 png파일로 다운로드되고, 선택되지 않은 경우<br/> jpg파일로 다운로드 됩니다.</strong>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="mt-3">
                                            <Form.Label>이미지 미리보기</Form.Label>
                                            <Image src={img_preview} className="m-auto border" style={{maxWidth:"300px"}}/>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-04-25 : 신경망 기반 인공지능이 피사체를 제외한 이미지의 배경을 제거합니다.</span>
                                                        <span>2023-08-23 : 사람의 신체가 피사체인 이미지의 배경을 제거하는데 적합합니다.</span>
                                                        <span>2025-02-02[권장] : 고성능 배경제거 모델을 사용합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="float-end mt-4">
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{Request();}}>배경제거</Button>
                                            </InputGroup>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Form.Label>결과이미지</Form.Label>
                                        <Row>
                                            <Image src={img_result} className="m-auto border mt-5" style={{maxWidth:"450px"}}/>
                                        </Row>
                                        <Row className="mt-5">
                                            {img_get !== "" ?
                                                <InputGroup>
                                                    <Button className="m-auto" onClick={()=>{downloadFile(img_result)}}>다운로드</Button>
                                                </InputGroup>
                                                :
                                                ""
                                            }
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="batchImage" title="여러 이미지"  className="p-3">
                            <Row>
                                <Col lg={6}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label>API버전 선택</Form.Label>
                                        </Row>
                                        <Row>
                                            <InputGroup>
                                                <Form.Select value={api_version} onChange={onChangeAPIVersion}>
                                                    {
                                                        api_list.map(function(a, idx){
                                                            return(
                                                            <option key={idx} value={a}>{a}</option>
                                                            );
                                                        })
                                                    }
                                                </Form.Select>
                                            </InputGroup>
                                        </Row>
                                        <Row>
                                            <Form.Group className="my-3">
                                                <Form.Label>이미지 압축파일 선택</Form.Label>
                                                <Form.Control type="file" accept="application/zip" id="zip_file" onChange={onLoadFile}/>
                                            </Form.Group>
                                        </Row>
                                        <Row className="my-2">
                                            <Form.Group>
                                                <Form.Check name="transparent" type="switch" checked={transparent} onChange={handleTransparent} label="배경 투명하게"></Form.Check>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="text-secondary">
                                                <Row>
                                                    <strong className="fs-6">※ 배경 투명하게가 선택되면 png파일로 다운로드되고, 선택되지 않은 경우<br/> jpg파일로 다운로드 됩니다.</strong>
                                                </Row>
                                            </Container>
                                        </Row>
                                        <Row className="float-end"> 
                                            <InputGroup>
                                                <Button variant="secondary" onClick={()=>{resetConfig();}}>초기화</Button>
                                                <Button onClick={()=>{alert("본 기능은 정식버전에서만 사용가능합니다.\n크레딧 구입 후 이용해주세요."); window.open('/subscribe');}}>배경제거</Button>
                                            </InputGroup>
                                        </Row>
                                        <Row className="mt-5">
                                            <Form.Group>
                                                <Form.Label>상세로그</Form.Label>
                                                <Form.Control as="textarea"style={{backgroundColor:"white"}} rows={13} value="" readOnly/>
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Container className="mt-3 text-secondary">
                                                <Row>
                                                    <strong>※ API 버전별 특징 안내</strong>
                                                </Row>
                                                <Row className="fs-6 ps-2">
                                                    <Row>
                                                        <span>2023-04-25 : 신경망 기반 인공지능이 피사체를 제외한 이미지의 배경을 제거합니다.</span>
                                                        <span>2023-08-23 : 사람의 신체가 피사체인 이미지의 배경을 제거하는데 적합합니다.</span>
                                                        <span>2022-02-02[권장] : 고성능 배경제거 모델을 사용합니다.</span>
                                                    </Row>
                                                </Row>
                                            </Container>
                                        </Row>
                                        
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="h-100">
                                        <Form.Label>작업목록</Form.Label>
                                        <Row className="border py-3 m-lg-3  p-lg-3" style={{overflowY:"scroll", height:"700px"}}>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Container>
           </section>
        </>
    );

}

export default BGRM_demo_play;